import React from 'react'
import Header from '../components/header'
import Footer from '../components/footer'
import { Link } from 'gatsby'

const NotFoundPage = (): React.ReactElement => (
  <>
    <Header />
      <main>
        <title>Not found</title>

        <section className="error">
          <div className="error__header">
            <h1 className="error__title">
              We can&apos;t find the page you are looking for
            </h1>
            <p className="error__subtitle">Let&apos;s just go back to the beginning...</p>
          </div>

          <Link to="/" title="Back to the qibri homepage">Back to start</Link>
        </section>
      </main>
    <Footer />
  </>
)

export default NotFoundPage
